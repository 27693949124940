import styled from "styled-components"

import { ErrorAlert } from "src/components/Account/BillingPortal/ErrorAlert"
import { PlanRenewalBreakdownTable } from "src/components/Account/BillingPortal/PlanRenewalBreakdown/PlanRenewalBreakdownTable"
import { PlanRenewalPeriod } from "src/components/Account/BillingPortal/PlanRenewalBreakdown/PlanRenewalPeriod"
import { PlanRenewalTotal } from "src/components/Account/BillingPortal/PlanRenewalBreakdown/PlanRenewalTotal"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import {
  useFetchPlanRenewalEstimate,
  useFetchPlanRenewalEstimateBreakdown,
} from "src/data/billing/queries/billingQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { Divider } from "src/ui/Divider/Divider"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const LIMIT = 50

export function EstimatedRenewalDetails() {
  const { offset, setOffset } = useUrlPager()

  const user = useGetUser()

  const fetchPlanRenewalEstimate = useFetchPlanRenewalEstimate({
    userId: user.user_id,
  })

  const fetchPlanRenewalEstimateBreakdown =
    useFetchPlanRenewalEstimateBreakdown({
      userId: user.user_id,
      filter: {
        limit: LIMIT,
        offset,
      },
      options: {
        keepPreviousData: true,
      },
    })

  const { t, langKeys } = useTranslate()

  if (
    fetchPlanRenewalEstimate.isError ||
    fetchPlanRenewalEstimateBreakdown.isError
  ) {
    return <ErrorAlert />
  }

  return (
    <BreadcrumbView
      title={
        <TitleWrapper>
          {t(langKeys.plan_renewal_estimate_breakdown_title, {
            date: fetchPlanRenewalEstimateBreakdown.data?.renewed_from,
          })}
          {!fetchPlanRenewalEstimateBreakdown.data?.renewed_from && (
            <MSkeleton inline width="10ch" />
          )}
        </TitleWrapper>
      }
      breadcrumbs={[
        {
          to: Routes.AccountBilling.location(),
          label: t(langKeys.plan_renewal_estimate_breakdown_back_breadcrumb),
        },
        {
          to: Routes.EstimatedRenewalDetails.location(),
          label: t(langKeys.plan_renewal_estimate_breakdown_breadcrumb),
        },
      ]}
      description={
        <PlanRenewalPeriod
          renewedFrom={
            fetchPlanRenewalEstimateBreakdown.data?.renewed_from ?? ""
          }
          renewedUntil={
            fetchPlanRenewalEstimateBreakdown.data?.renewed_until ?? ""
          }
          loading={fetchPlanRenewalEstimateBreakdown.isLoading}
        />
      }
    >
      <PlanRenewalTotal
        renewalAmount={fetchPlanRenewalEstimate.data?.formatted_renewal_amount}
        planAmount={fetchPlanRenewalEstimate.data?.plan_amount.formatted_amount}
        addonAmount={
          fetchPlanRenewalEstimate.data?.addon_amount.formatted_amount
        }
        isLoading={fetchPlanRenewalEstimate.isLoading}
      />

      <MText variant="bodyS">
        {t(langKeys.plan_renewal_estimate_breakdown_description)}
      </MText>

      <Divider $margin={spacing.L} />

      <PlanRenewalBreakdownTable
        breakdown={fetchPlanRenewalEstimateBreakdown.data?.breakdown}
        renewedUntil={fetchPlanRenewalEstimateBreakdown.data?.renewed_until}
        isLoading={fetchPlanRenewalEstimateBreakdown.isLoading}
        isLoadingNewData={fetchPlanRenewalEstimateBreakdown.isPreviousData}
      />

      <PagerWrapper>
        <Pager
          offset={offset}
          limit={LIMIT}
          totalCount={
            fetchPlanRenewalEstimateBreakdown.data?.paging.total_count
          }
          setOffset={setOffset}
        />
      </PagerWrapper>

      <MText variant="bodyS">
        *{t(langKeys.plan_renewal_breakdown_addon_note)}
      </MText>
    </BreadcrumbView>
  )
}

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL2};
`
